/*
* Saga to get balance
* */

import {all, put, takeEvery} from 'redux-saga/effects';
import axios from 'axios';
import {toast} from 'react-toastify';

import {
    GET_BALANCE,
    GET_BALANCE_GRAPH,
    GET_TRANSACTIONS,
    GET_TRANSACTIONS_ADDRESS,
    REGISTER_HASH,
    GET_CREDIT,
    GET_WEB_ANALYSIS,
    SEARCH_COIN_ADDRESS,
} from '../actions/types';
import {
    getBalanceFailure,
    getBalanceGraphFailure,
    getBalanceGraphSuccess,
    getBalanceSuccess,
    getTransactionAddressFailure,
    getTransactionAddressSuccess,
    getTransactionFailure,
    getTransactionSuccess,
    getWebAnalysisFailure,
    getWebAnalysisSuccess,
    getCreditSuccess,
    getCreditFailure,
    getSearchedAddressFailure,
    getSearchedAddressSuccess, registerHashSuccess, registerHashFailure,
} from '../actions';
import {apiUrl} from '../config/helper';

function* getBalance(action) {
    const {coinAddress, selectedCoin, type} = action.payload;
    try {
        const res = yield axios.post(`${apiUrl}/api/coin-balance`, {
            coin: selectedCoin,
            address: coinAddress,
            type
        });
        yield put(getBalanceSuccess(res.data.data));
    } catch (e) {
        yield put(getBalanceFailure(e));
    }
}

function* getBalanceGraph(action) {
    const {coinAddress, selectedCoin, type} = action.payload;
    try {
        const res = yield axios.post(`${apiUrl}/api/coin-transactions`, {
            coin: selectedCoin,
            address: coinAddress,
            type
        });
        yield put(getBalanceGraphSuccess(res));

    } catch (e) {
        yield put(getBalanceGraphFailure(e));
    }
}

function* getTransactions(action) {
    const {coinAddress, selectedCoin, type, limit, page} = action.payload;
    try {
        const res = yield axios.post(`${apiUrl}/api/coin-transactions`, {
            coin: selectedCoin,
            address: coinAddress,
            type,
            limit,
            page
        });
        yield put(getTransactionSuccess(res.data.data));
    } catch (e) {
        yield put(getTransactionFailure(e));
    }
}

function* getWebAnalysis(action) {
    const {coinAddress} = action.payload;
    try {
        const res = yield axios.post(`${apiUrl}/api/web-analysis`, {
            address: coinAddress
        });
        if (res.data.meta.status === 1) yield put(getWebAnalysisSuccess(res.data.data));
        else {
            // toast.error(res.data.meta.message);
            yield put(getWebAnalysisFailure(res.data.data))
        }
    } catch (e) {
        yield put(getWebAnalysisFailure(e));
    }
}

function* getTransactionsAddress(action) {
    const {coinAddress, selectedCoin, limit} = action.payload;
    try {
        const res = yield axios.post(`${apiUrl}/api/coin-address`, {
            coin: selectedCoin,
            address: coinAddress,
            limit: limit
        });
        yield put(getTransactionAddressSuccess(res));
    } catch (e) {
        yield put(getTransactionAddressFailure(e));
    }
}

function* getCreditDataRequest() {
    try {
        const res = yield axios.get(`${apiUrl}/api/remaining-credits`);
        yield put(getCreditSuccess(res))
    } catch (e) {
        yield put(getCreditFailure(e))
    }
}

function* registerHashAPIRequest(actions) {
    const {json_data,provider,address,ipAddress,click} =actions.payload;
    try{
        const res = yield axios.post(`${apiUrl}/api/reporting`, {json_data,provider,address,ipAddress});
        yield put(registerHashSuccess(res.data.data));
        click();
        if(res.data.meta.status === 0) toast.error(res.data.meta.message);
        if(res.data.meta.status === 1) toast.success(res.data.meta.message);
    } catch (e) {
        yield put(registerHashFailure(e))
    }
}
function* searchHopAddress(action) {
    try {
        const res = yield axios.post(`${apiUrl}/api/scoring`, action.payload);
        yield put(getSearchedAddressSuccess(res.data));
    } catch (e) {
        yield put(getSearchedAddressFailure(e));
    }
}


/* watch function for Get Balance */
export function* watchGetBalanceApi() {
    yield takeEvery(GET_BALANCE, getBalance);
}

/* watch function for Get Transaction */
export function* watchGetTransactionsApi() {
    yield takeEvery(GET_TRANSACTIONS, getTransactions);
}

/* watch function for Get Graph Data*/
export function* watchGetBalanceGraphApi() {
    yield takeEvery(GET_BALANCE_GRAPH, getBalanceGraph);
}

/* watch function for Get Web Analysis Data*/
export function* watchGetWebAnalysisApi() {
    yield takeEvery(GET_WEB_ANALYSIS, getWebAnalysis);
}

/* watch function for Get transaction hop1*/
export function* watchGetTransactionsAddressApi() {
    yield takeEvery(GET_TRANSACTIONS_ADDRESS, getTransactionsAddress);
}

/*watch function for getting credit*/

export function* watchGetCreditApi() {
    yield takeEvery(GET_CREDIT, getCreditDataRequest)

}

/*watch function for Register Hash */
export function* watchRegisterHash() {
    yield takeEvery(REGISTER_HASH, registerHashAPIRequest)
}

/* watch function for Search coin address */
export function* watchSearchAddressApi() {
    yield takeEvery(SEARCH_COIN_ADDRESS, searchHopAddress);
}

export default function* rootSaga() {
    yield all([
        watchGetBalanceApi(),
        watchGetTransactionsApi(),
        watchGetBalanceGraphApi(),
        watchGetWebAnalysisApi(),
        watchGetTransactionsAddressApi(),
        watchGetCreditApi(),
        watchRegisterHash(),
        watchSearchAddressApi()
    ]);
}
