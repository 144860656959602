/*
* Action to get balance
* */

import {
    GET_BALANCE,
    GET_BALANCE_FAILURE,
    GET_BALANCE_GRAPH,
    GET_BALANCE_GRAPH_FAILURE,
    GET_BALANCE_GRAPH_SUCCESS,
    GET_BALANCE_SUCCESS,
    GET_CREDIT,
    GET_CREDIT_FAILURE,
    GET_CREDIT_SUCCESS,
    GET_TRANSACTIONS,
    GET_TRANSACTIONS_ADDRESS,
    GET_TRANSACTIONS_ADDRESS_FAILURE,
    GET_TRANSACTIONS_ADDRESS_SUCCESS,
    GET_TRANSACTIONS_FAILURE,
    GET_TRANSACTIONS_SUCCESS,
    GET_WEB_ANALYSIS,
    GET_WEB_ANALYSIS_FAILURE,
    GET_WEB_ANALYSIS_SUCCESS,
    REGISTER_HASH, REGISTER_HASH_FAILURE, REGISTER_HASH_SUCCESS,
    SEARCH_COIN_ADDRESS,
    SEARCH_COIN_ADDRESS_FAILURE,
    SEARCH_COIN_ADDRESS_SUCCESS,
} from './types';

/*For Get Balance In Balance Tab*/
export const getBalance = (payload) => ({
    type: GET_BALANCE,
    payload,
});

export const getBalanceSuccess = (payload) => ({
    type: GET_BALANCE_SUCCESS,
    payload,
});

export const getBalanceFailure = (payload) => ({
    type: GET_BALANCE_FAILURE,
    payload,
});

/*For Get Transaction In Transaction Tab*/
export const getTransaction = (payload) => ({
    type: GET_TRANSACTIONS,
    payload
});

export const getTransactionSuccess = (payload) => ({
    type: GET_TRANSACTIONS_SUCCESS,
    payload
});

export const getTransactionFailure = (payload) => ({
    type: GET_TRANSACTIONS_FAILURE,
    payload
});

/*For Get Balance graph In Balance Tab*/
export const getBalanceGraph = (payload) => ({
    type: GET_BALANCE_GRAPH,
    payload
});

export const getBalanceGraphSuccess = (payload) => ({
    type: GET_BALANCE_GRAPH_SUCCESS,
    payload
});

export const getBalanceGraphFailure = (payload) => ({
    type: GET_BALANCE_GRAPH_FAILURE,
    payload
});

/*Get WEb Analysis Data*/

export const getWebAnalysis = (payload) => ({
    type: GET_WEB_ANALYSIS,
    payload
});

export const getWebAnalysisSuccess = (payload) => ({
    type: GET_WEB_ANALYSIS_SUCCESS,
    payload
});

export const getWebAnalysisFailure = (payload) => ({
    type: GET_WEB_ANALYSIS_FAILURE,
    payload
});


/*For Get Transaction Address In Transaction Tab*/

export const getTransactionAddress = (payload) => ({
    type: GET_TRANSACTIONS_ADDRESS,
    payload
});

export const getTransactionAddressSuccess = (payload) => ({
    type: GET_TRANSACTIONS_ADDRESS_SUCCESS,
    payload
});

export const getTransactionAddressFailure = (payload) => ({
    type: GET_TRANSACTIONS_ADDRESS_FAILURE,
    payload
});

/*For getting credit of user  In Scoring Tab*/

export const getCredit = () => ({
    type: GET_CREDIT,
});

export const getCreditSuccess = (payload) => ({
    type: GET_CREDIT_SUCCESS,
    payload
});

export const getCreditFailure = (payload) => ({
    type: GET_CREDIT_FAILURE,
    payload
});

/*For Register Hash in Scroing Collapse Tab*/
export const registerHash = (payload) => ({
    type: REGISTER_HASH,
    payload
});

/*For Register Hash in Scroing Collapse Tab*/
export const registerHashFailure = (payload) => ({
    type: REGISTER_HASH_FAILURE,
    payload
});
/*For Register Hash in Scroing Collapse Tab*/
export const registerHashSuccess = (payload) => ({
    type: REGISTER_HASH_SUCCESS,
    payload
});

export const getSearchedAddress = (payload) => ({
    type: SEARCH_COIN_ADDRESS,
    payload,
});

export const getSearchedAddressSuccess = (payload) => ({
    type: SEARCH_COIN_ADDRESS_SUCCESS,
    payload,
});

export const getSearchedAddressFailure = (payload) => ({
    type: SEARCH_COIN_ADDRESS_FAILURE,
    payload,
});
