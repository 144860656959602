/**
 * Created By Sanjay
 * Reducer Index File
 */

import {combineReducers} from 'redux';

import SearchCoinReducer from './searchCoinReducer';
import CoinDetailsReducer from './coinDetailsReducer';
import SignupReducer from './signupRequestReducer';
import SignInReducer from './signinRequestReducer';
import ForgotPassword from './forgotPasswordReducer';
import AddressFetch from './autoCompleteAddressReducer'
import marketCap from './marketCapReducer'
import SuperAdminHeaderReducer from "./superAdminHeaderReducer";
import SuperAdminReducer from './superAdminReducer';
import MonitoringReducer from './monitoringReducer';
import BackOfficeAdminReducer from "./backOfficeAdminReducer";
import BackOfficeAdminHeaderReducer from './backOfficeAdminHeaderReducer';
import MyUNIAMLReducer from './myUniamlReducer';
import ScoringHistoryReducer from './scoringHistoryReducer';
import HeaderReducer from './headerReducer';

const appReducer = combineReducers({
    SearchCoinReducer,
    CoinDetailsReducer,
    SignupReducer,
    SignInReducer,
    ForgotPassword,
    AddressFetch,
    marketCap,
    SuperAdminHeaderReducer,
    SuperAdminReducer,
    MonitoringReducer,
    BackOfficeAdminReducer,
    BackOfficeAdminHeaderReducer,
    MyUNIAMLReducer,
    ScoringHistoryReducer,
    HeaderReducer
});

const reducers = (state, action) => {
    if (action.type === 'RESET_STORE' && state) {
        state.CoinDetailsReducer = undefined;
        state.SearchCoinReducer = undefined;
    }
    return appReducer(state, action)
};

export default reducers;
