import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from "react-toastify";
import jwt_decode from 'jwt-decode';
import App from './App';
import configureStore from './store';
import { getToken, removeToken, setAuthToken } from "./config";

if (window.location.protocol == "http:") {
 window.location.href = window.location.href.replace('http:','https:');
} 

if(localStorage.token === 'undefine' || localStorage.token === 'undefined') {
    removeToken();
    window.location.href = '/signin';
} else {
    if (localStorage.token) {
        // Set auth token header auth
        setAuthToken(localStorage.token);
        // Decode token and get user info and exp
        const decoded = jwt_decode(localStorage.token);
        // Check for expired token
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            removeToken();
            // Redirect to login
            window.location.href = '/signin';
        }
    }
}

const MainApp = () => {
    useEffect(() => {
        setAuthToken(getToken());
    });
    return (
        <Provider store={configureStore()}>
            <App/>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
        </Provider>
    )
};

ReactDOM.render(<MainApp/>, document.getElementById('root'));
