import moment from "moment";
import scorechain from "../assets/img/searchCoin/scorechain.svg";
import blockchain_group from "../assets/img/coinDetails/big-second.svg";
import cipher_trace from "../assets/img/coinDetails/cipher-second.svg";
import chainalysis from "../assets/img/coinDetails/chainalysis.svg";
import crystal from "../assets/img/coinDetails/crystal-white.svg";
import ey from "../assets/img/searchCoin/ey.svg";

//SYGNUM
export const apiUrl = 'https://api-sygnum.universalaml.com';

export const satoshiToBTC = (value) => value * 0.00000001;

export const weiToEther = (value) => value / 1000000000000000000;

export const setToken = (token) => {
  localStorage.setItem('token', token);
};

export const getToken = () => localStorage.getItem('token');

export const setUserType = (type) => {
  localStorage.setItem('type', type);
};

export const getUserType = () => localStorage.getItem('type');

export const removeToken = () => {
  localStorage.removeItem('token');
};

export const convertTimestamp = (timestamp) => {
  return moment(timestamp*1000).format("MMM YY");
};

export const setUserIp = (ip) => {
  localStorage.setItem('ip', ip)
};

export const getUserIP = () => localStorage.getItem('ip');

export const updateScore = {
  'Every Day' : 0,
  'Every Week': 1,
  'Every Month': 2,
  'No Update': 3
};

export const ScoreFromAPI = {
  0: 'Every Day',
  1: 'Every Week',
  2: 'Every Month',
  3: 'No Update'
};

export const providerLogoNew = {
  scorechain, blockchain_group, cipher_trace, chainalysis, crystal, ey
};
