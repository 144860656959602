import React from "react";
import {fadeInDownBig} from 'react-animations';
import Radium, {StyleRoot} from 'radium';

import BTC from '../assets/img/header/Bitcoin.svg';
import BCH from '../assets/img/header/Bitcoin-cash.svg';
import DASH from '../assets/img/header/Dash.svg';
import ETH from '../assets/img/header/ETH.svg';
import LTC from '../assets/img/header/Litcoin.svg';
import XRP from '../assets/img/header/Ripple.svg';
import crystal from "../assets/img/coinDetails/crystal-white.svg";
import scorechain from "../assets/img/searchCoin/scorechain.svg";
import cipher_trace from "../assets/img/cipher-for-table-header.svg";
import blockchain_group from "../assets/img/big-for-table-header.svg";
import merkle from '../assets/img/searchCoin/merklw-science.png';
import chainalysis from '../assets/img/coinDetails/chainalysis.svg';
import ey from '../assets/img/searchCoin/ey.svg';
import eys from '../assets/img/coinDetails/ey-white.svg';
import dmg from '../assets/img/coinDetails/DMGBlack.png';

export const coinLogo = {
    BTC, ETH, LTC, BCH, XRP, DASH
};

export const coinName = {
    BTC: 'bitcoin',
    ETH: 'ethereum',
    DASH: 'dash',
    LTC: 'litecoin',
    BCH: 'bitcoin-cash',
    XRP: 'ripple',
};

export const providerLogo = {
    scorechain, blockchain_group, cipher_trace, chainalysis, crystal, ey, dmg, merkle
};

export const providers = [
    blockchain_group, chainalysis, cipher_trace, crystal, dmg, eys, merkle, scorechain
];

export const scoreForXDLT = {
    0: 'A+',
    1: 'A',
    2: 'A-',
    3: 'A-',
    4: 'B+',
    5: 'B',
    6: 'B-',
    7: 'C+',
    8: 'C',
    9: 'C-',
    10: 'C-'
};
export const animationStyle = {
    animationObject: {
        animation: 'x 1s ',
        animationName: Radium.keyframes(fadeInDownBig)
    }
};
export const Animation = (props) => {
    return (
        <StyleRoot>
            {props.children}
        </StyleRoot>
    )
};
